import React from 'react'
import Layout from '../components/Layout'
import styled from '@emotion/styled';
import Header from "../components/Header";
import Container from "../components/Container";

import backgroundPortable from "../img/bg-header-small.jpg"
import backgroundNotebook from "../img/bg-header-notebook.jpg"
import backgroundFullHD from "../img/bg-header-fullhd.jpg"
import notFound from "../img/404.svg"

const HeaderWrapper = styled("div")`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url(${backgroundPortable});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundNotebook});
  }
  @media screen and (min-width: 1440px) {
    background-image: url(${backgroundFullHD});
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const NotFoundWrapper = styled("div")`
  padding-top: 6rem;
  padding-bottom: 6rem;
  img {
    display: block;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
`

const NotFoundPage = () => (
  <Layout>
    <HeaderWrapper>
      <Header />
    </HeaderWrapper>
    <Container>
      <NotFoundWrapper>
        <img alt="404" src={notFound}/>
      </NotFoundWrapper>
    </Container>
  </Layout>
)

export default NotFoundPage
